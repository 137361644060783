


function Resume() {
    return (
        <>
            <div className="emptyspace"></div>
            <h2 className="resumePart crimson" id="Resume"> Here is my&nbsp;<a href="Leah-Harden-Resume.pdf">Resume</a></h2>
            <div className="emptyspace"></div>
        </>
    )
}
export default Resume;