function BigProject() {



    return (
        <div>

            <div className="bigprojectCard">
                <div className="shadowBigProject">

                    <a href='https://github.com/Qaizen/curious-chronicles' className="bigprojectA">
                        <img src="photos/projects/CC.png" className="bigprojectPhoto" alt='The front page of Curious Chronicles' />
                        <h2 className="bigprojectTitle cavent">Curious Chronicles
                        </h2>
                    </a>
                </div>
                <p className="bigprojectDesc crimson">The development of this sophisticated web application is driven by the utilization of an extensive technology stack, including HTML, CSS, JavaScript, MongoDB, Express, React Node, GraphQL, and Node Package Manager. Its purpose is to establish an innovative online platform specifically designed for children's journaling.</p>
            </div>

        </div>
    );
}
export default BigProject;