


function Title() {
    return (

        <h1 className="crimson TrueTitle">Leah Xinli Harden</h1>

        // maybe Xinli?
    );

}

export default Title;