

function Social() {
    return (
        <div>
            <a href="https://instagram.com/leahharden_?igshid=OGQ5ZDc2ODk2ZA==">

                <img src='photos/Inst.png' alt="instgram" className="icon" />
            </a>
            <a href="https://www.linkedin.com/in/leahxharden/">

                <img src='photos/linkedin.png' alt="Linkedin" className="icon" />
            </a>
            <a href="https://github.com/Leah-Harden/">

                <img src='photos/git.png' alt="GitHub" className="icon" />
            </a>


        </div >

    );

}


export default Social;