
import Form from './Form'

function Contact() {



    return (
        <div className="col center Contact" id='Contact'>

            <h2 className='Title cavent'>Let's get in contact
            </h2>
            <h3 className='TitleContact crimson'>Please email me at&nbsp;leahxharden@gmail.com</h3>
            {/* <Form /> */}
            <div className="emptyspace"></div>
        </div>

    )
}


export default Contact;